<template>
    <div id="testLoadingContainer">
        <div id="titleLoading" class="item"></div>
        <div id="testBoxGroupLoading">
            <div class="testBoxLoading">
                <div id="testBoxTypeLoading" class="item"></div>
                <div id="testBoxContentLoading">
                    <div id="textBoxInfo1Loading">
                        <div class="item"></div>
                        <div class="item"></div>
                        <div class="item"></div>
                        <div id="info1Small" class="item"></div>
                    </div>
                    <div id="textBoxInfo2Loading">
                        <div class="textBoxVisualInfo">
                            <div class="logo item"></div>
                            <div class="text1 item"></div>
                            <div class="text2 item"></div>
                        </div>
                        <div class="textBoxVisualInfo">
                            <div class="logo item"></div>
                            <div class="text1 item"></div>
                            <div class="text2 item"></div>
                        </div>
                        <div class="textBoxVisualInfo">
                            <div class="logo item"></div>
                            <div class="text1 item"></div>
                            <div class="text2 item"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="testBoxLoading">
                <div id="testBoxTypeLoading" class="item"></div>
                <div id="testBoxContentLoading">
                    <div id="textBoxInfo1Loading">
                        <div class="item"></div>
                        <div class="item"></div>
                        <div class="item"></div>
                        <div id="info1Small" class="item"></div>
                    </div>
                    <div id="textBoxInfo2Loading">
                        <div class="textBoxVisualInfo">
                            <div class="logo item"></div>
                            <div class="text1 item"></div>
                            <div class="text2 item"></div>
                        </div>
                        <div class="textBoxVisualInfo">
                            <div class="logo item"></div>
                            <div class="text1 item"></div>
                            <div class="text2 item"></div>
                        </div>
                        <div class="textBoxVisualInfo">
                            <div class="logo item"></div>
                            <div class="text1 item"></div>
                            <div class="text2 item"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="testBoxLoading">
                <div id="testBoxTypeLoading" class="item"></div>
                <div id="testBoxContentLoading">
                    <div id="textBoxInfo1Loading">
                        <div class="item"></div>
                        <div class="item"></div>
                        <div class="item"></div>
                        <div id="info1Small" class="item"></div>
                    </div>
                    <div id="textBoxInfo2Loading">
                        <div class="textBoxVisualInfo">
                            <div class="logo item"></div>
                            <div class="text1 item"></div>
                            <div class="text2 item"></div>
                        </div>
                        <div class="textBoxVisualInfo">
                            <div class="logo item"></div>
                            <div class="text1 item"></div>
                            <div class="text2 item"></div>
                        </div>
                        <div class="textBoxVisualInfo">
                            <div class="logo item"></div>
                            <div class="text1 item"></div>
                            <div class="text2 item"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<style lang="scss" scoped>
*{
    box-sizing: border-box;
}
.item{
    background: #E6E6E6;
    border-radius: 55px;
    position: relative;
}
.item:after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    transform: translateX(-100px);
    background: linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.4), transparent);
    animation: loading 0.8s infinite;
}
@keyframes loading {
    100% {
        transform: translateX(100%);
    }
}
    #testLoadingContainer{
        display: flex;
        flex-direction: column;
        justify-content: center;
        #titleLoading{
            margin-top: 20px;
            width: 70%;
            height: 38px;
        }
        #testBoxGroupLoading{
            margin-top: 40px;
            border-top: 3px solid #E6E6E6;
            padding: 20px;
            padding-top: 40px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            >.testBoxLoading{
                margin-bottom: 40px;
                width: 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                >#testBoxTypeLoading{
                    width: 60px;
                    height: 9px;
                    margin-bottom: 20px;
                }
                >#testBoxContentLoading{
                    display: grid;
                    grid-template-columns: 45% 55%;
                    >#textBoxInfo1Loading{
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        >div{
                            width: 80%;
                            height: 16px;
                            margin-bottom: 20px;
                            &#info1Small{
                                width: 20%;
                                height: 9px;
                                margin-left: 30px;
                            }
                        }
                    }
                    >#textBoxInfo2Loading{
                        display: grid;
                        grid-template-columns: 1fr 1fr 1fr;
                        >.textBoxVisualInfo{
                            height: 100%;
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            align-items: center;
                            >.logo{
                                border-radius: 50%;
                                width: 35px;
                                height: 35px;
                                margin-bottom: 10px;
                            }
                            >.text1{
                                width: 71px;
                                height: 9px;
                                margin-bottom: 10px;
                            }
                            >.text2{
                                width: 82px;
                                height: 16px;
                            }
                        }
                    }
                }
            }
        }
    }
</style>