<template>
    <div id="noTestContainer">
        <h1>{{ $t('BUILDING_NEW_TEST') }}</h1>
        <p>
            {{ $t('Testers_shared_index_change_criteria') }}
        </p>
        <img src="@/assets/svg/noTest.svg" alt="" />
    </div>
</template>
<style lang="scss" scoped>
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700;900&display=swap');
* {
    box-sizing: border-box;
}
#noTestContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    > h1 {
        font-weight: bold;
        font-size: 28px;
        color: #341566;
        font-family: 'Bariol';
        font-style: normal;
        font-weight: 700;
    }
    > p {
        font-family: 'Bariol';
        font-size: 20px;
        color: gray;
    }
    > img {
        width: 100%;
        margin-top: 20px;
        height: auto;
        max-width: 600px;
    }
}
@media screen and (min-width: 1000px) {
#noTestContainer{
    align-items: flex-start;
    > h1{
        font-size: 32px;
    }
    > img {
        width: 80%;
        margin-top: 20px;
        height: auto;
        margin-top: 80px;
        margin-left: auto;
        margin-right: auto;
        max-width: 800px;
    }
}
}
</style>