<template>
    <div id="testLoadingMobileContainer">
        <div id="title" class="item"></div>
        <div id="contentGroup">
            <div class="testBox">
                <div class="type">
                    <div class="circle item"></div>
                    <div class="item"></div>
                </div>
                <div class="testTitle item"></div>
                <div class="testDate item"></div>
                <div class="visualInfo item">
                    <div class="circle item"></div>
                    <div class="item"></div>
                    <div class="circle second item"></div>
                    <div class="item"></div>
                </div>
                <div class="button item"></div>
            </div>
            <div class="testBox">
                <div class="type">
                    <div class="circle item"></div>
                    <div class="item"></div>
                </div>
                <div class="testTitle item"></div>
                <div class="testDate item"></div>
                <div class="visualInfo item">
                    <div class="circle item"></div>
                    <div class="item"></div>
                    <div class="circle second item"></div>
                    <div class="item"></div>
                </div>
                <div class="button item"></div>
            </div>         
            <div class="testBox">
                <div class="type">
                    <div class="circle item"></div>
                    <div class="item"></div>
                </div>
                <div class="testTitle item"></div>
                <div class="testDate item"></div>
                <div class="visualInfo item">
                    <div class="circle item"></div>
                    <div class="item"></div>
                    <div class="circle second item"></div>
                    <div class="item"></div>
                </div>
                <div class="button item"></div>
            </div>         
        </div>
    </div>
</template>
<style lang="scss" scoped>
*{
    box-sizing: border-box;
}
.item{
    background: #E6E6E6;
    position: relative;
    border-radius: 10px;
}
.item:after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    transform: translateX(-100px);
    background: linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.4), transparent);
    animation: loading 0.8s infinite;
}
@keyframes loading {
    100% {
        transform: translateX(100%);
    }
}
#testLoadingMobileContainer{
    #title{
        margin-top: 20px;
        width: 90%;
        height: 20px;
    }
    #contentGroup{
        width: 100%;
        padding: 20px 30px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .testBox{
            margin-top: 30px;
            width: 100%;
            height: fit-content;
            .type{
                width: 30%;
                margin-bottom: 20px;
                display: flex;
                align-items: center;
                .circle{
                    width: 20px;
                    height: 20px;
                    border-radius: 50%;
                }
                >div{
                    width: 75%;
                    margin-left: 5%;
                    height: 10px;
                }
            }
            .testTitle, .testDate {
                width: 100%;
                height: 16px;
            }
            .testTitle{
                margin-bottom: 20px;
            }
            .visualInfo{
                width: 80%;
                margin-left: auto;
                margin-right: auto;
                margin-top: 30px;
                margin-bottom: 30px;
                display: flex;
                align-items: center;
                >div{
                    width: 35%;
                    height: 10px;
                }
                .circle{
                    width: 20px;
                    height: 20px;
                    border-radius: 50%;
                    margin-right: 5%;
                    &.second{
                        margin-left: 10%;
                    }
                }
            }  
            >.button{
                width: 70%;
                margin-left: auto;
                margin-right: auto;
            }  
        }
    }
}
</style>