
import { defineComponent } from 'vue'
import { Capacitor } from '@capacitor/core'
import authStore from '@/store/auth.store';

export default defineComponent({
    props: {
        watFocus:{
            type: Object,
            default: () => {}
        }
    },
    methods:{
        open(){
            if(Capacitor.isNativePlatform()){
                const iframeUrlFocus: any = `${process.env.VUE_APP_URL}/app/focus-preview/${this.watFocus.identifier}/${this.watFocus.slot.slotHash}?authToken=${authStore.state.authToken}`
                window.open(iframeUrlFocus)
            }
            else{
                this.$router.push({name:'focusPreview', params:{
                    id: this.watFocus.identifier,
                    slotHash: this.watFocus.slot.slotHash
                }})
            }
        }
    }
})
